import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import BodyText from '@components/bodyText';
import Contact from '../../components/contact';
import Hero from '../../components/hero';
import Layout from '@components/layout';

const SekkiLan = ({ data }) => {
  const headLine = data.contentfulDescriptionComponent.headLine;
  const bodyText = data.contentfulDescriptionComponent.bodyText;

  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/sekki', name: 'SEKKI' },
        ]}
        title="Kredit Blocket"
      />
      <div id="first">
        <BodyText bodyText={bodyText} />
      </div>
      <Contact hideFAQ />
    </Layout>
  );
};

export default SekkiLan;

SekkiLan.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query BlocketbetalningQuery {
    contentfulDescriptionComponent(
      id: { eq: "66339dc7-3354-584b-a3c4-6562e4ca2cb2" }
    ) {
      id
      headline
      bodyText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
